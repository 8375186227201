import type { ApiError } from '~/types/ApiError'
import type { ErrorData } from '~/types/ErrorData'

const formatErrors = (error: unknown): ErrorData | undefined => {
  if (!error) {
    return
  }
  const castedError = error as ApiError
  if (!castedError.data) {
    castedError.data = { errors: {}, message: castedError.message, status: castedError.status }
  } else {
    castedError.data.status = castedError.status
  }
  return castedError.data
}

export {
  formatErrors
}
